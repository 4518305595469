/*======================================================
** 			|     tab overrides       |  		  **
======================================================== */
.tab-content {
	padding-top: 2rem;
	padding-left: 1rem;
}

/*======================================================
** 			|     destination stuff       |  		  **
======================================================== */
.destination {
	.destination__intro,
	.destination__map,
	.destination__search,
	//.destination__desc,
	.destination__details {
		margin-bottom: 4rem;
	}

	.destination__page__title {
		margin-top: 2em;
		margin-bottom: 2em;
	}

	/*======================================================
	** 			|     duh       |  		  **
	======================================================== */
	.destination__search {
		.destination__search__form {
			@media (min-width: $screen-sm-min) {
				// desktop only
				display: flex;
				align-items: center;
				flex-direction: row;
				justify-content: flex-start;

				.destination__search__form__title,
				.form-group {
					margin: 0;
					margin-right: 1rem;
				}
			}
		}
	}
	/*======================================================
	** 		   individual destination details       |  	  **
	======================================================== */
	.destination__backbtn>.btn {
		font-size: 1.2rem;
		font-weight: 700;
	}
	.destination__details {
		border-bottom: 1px solid $gray-lighter;

		.nav-tabs {
			// indent the nav icons
			margin-left: 4rem;
			margin-bottom: 1rem;
		}

		@media (min-width: $screen-md-min) {
			.destination__details__row {
				// reverse order so that details show to left, image to right!!
				display: flex;
				flex-wrap: nowrap;
				flex-direction: row-reverse;
				justify-content: space-between;
			}
		}

		.destination__details__about {
			.destination__details__about__line {
				font-size: 1.4rem;
			}
		}
		.destination__details__gallery {
			$dest-image-carousel-height: 40rem;
			margin-bottom: 2rem;
			@media (min-width: $screen-md-min) {
				margin-bottom: 0;
			}
			img {
				max-height: $dest-image-carousel-height;
			}
			img {
				object-fit: contain;
				object-position: center;
			}
		}
	}
	.destination__desc {
		.destination__desc__title {
			margin: 0;
			margin-bottom: 2rem;
			line-height: 1.15;
		}
	}


	/*======================================================
	** 			|     nearby items       |  		  **
	======================================================== */
	.destination__mapnav {
		.destination__mapnav__address {
			margin: 0;
		}
	}
	.destination__nearby {
		.destination__nearby__item__distance {
			margin-left: 1rem;
			color: $brand-secondary;
		}
	}
	
}
/*======================================================
**   destination list page,
** ALSO destinations on BIKE PATH page
** ALSO mypath destination list
======================================================== */
.destination__list {
	.destination__list__item {
		padding: 2rem 0;
		border-bottom: .2em $brand-primary-light solid;
		margin-bottom: 2rem;

		.destination__list__item__img {
			@media (max-width: $screen-xs-max) {
				margin-bottom: 1rem;
			}

			.img-square {
				margin: 0 auto;
				height: 30rem;
				width: 30rem;

				position: relative;

				@media (min-width: $screen-sm-min) {
					height: 20rem;
					width: 20rem;
					width: 100%;
				}

				.img-contain {
					width: 100%;

					@supports (object-fit: cover) or (-o-object-fit: cover) {
						height: 100%;
						object-fit: cover;
						object-position: center;
					}
				}
			}
			
			.favoriteThisDest {
				&.isFavorite {
					// special color when it's a favorite?
				}
			}
		}
	}
}

.destination__details__about,
.destination__list__item__details {
	.line {
		margin-top: 6px;
		margin-bottom: 6px;
	}
	> ul,
	> ul > li,
	.tab-content ul,
	.tab-content li {
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: block;
	}
	li.destination__details__about__line {
		padding: 0 0 10px 35px;
		position: relative;
		min-height: 19px;
		.destination__details__about__line__icon {
			font-size: 1.2em;
			position: absolute;
			left: 0;
			top: 3px;
			color: $gray-light;
		}
	}
}

@media (min-width: $screen-sm-min) {
	.destination__details__about {
		ul.nav__about__tabs {
			li {
				border-left: solid 2px $gray-light;
				&:first-child {
					border-left: 0;
				}

				a {
					border: 0;
					border-radius: 0;
				}

				&.active {
					a, &:hover, &:focus {
						background-color: inherit !important;
					}
				}
			}
		}
	}
}

.destination__nearby--events {
	border-right: solid 1px $gray-lighter;
}

/*======================================================
** 			|     things to do row       | 		  **
======================================================== */
// Unused
/*.thingstodo__row {
	background-color: $brand-primary;
	color: $white;

	a {
		color: inherit;

		&:hover {
			color: inherit;
			text-decoration: underline;
		}
	}
}*
/*======================================================
** 			|     the attractions       |  		  **
======================================================== */
.attractions {
	margin-bottom: 1rem;

	.attractions__toggle {
		> .fa {
			vertical-align: bottom;
			font-size: 3.2rem;
			margin-right: 1rem;
		}
		border-radius: $border-radius-base;
		border: 0;
		background: transparent;
		box-shadow: none;

		margin-bottom: 1rem;

		font-size: 2rem;
		font-weight: 700;
		color: $brand-primary;
	}

	.yelpAttribution__logo {
		position: relative;
		top: -4px;
	}
}


/*======================================================
** 			|     the map       |  		  **
======================================================== */
/*

	moved to banner region!

#map-canvas {
	height: 400px;
}
*/



/*======================================================
** 			|     print styles for destinations       |  		  **
======================================================== */
@media print {
	.destination {
		.destination__search {
			// should not show search form!
			display: none !important;
		}
		.destination__backbtn {
			display: none !important;
		}
		.attractions {
			// should NOT show attractions on print!
			display: none !important;
		}
		.destination__list {
			.destination__list__item__img {
				max-width: 4in;
				margin: 0 auto 1rem;
			}
		}
		.destination__details__gallery {
			float: left;
			img {
				max-width: 4in;
			}
		}
	}
	.tagMenu {
		display: none !important;
	}
}
