/*======================================================
** 			|     "Recommended for you" feed.      |  		  **
======================================================== */
.recommendations {
	border-top: 1rem solid $gray-lighter;
	border-bottom: 1rem solid $gray-lighter;
	padding-top: 2rem;
	padding-bottom: 2rem;
	
	// watch out for header!
	position: relative;
	z-index: 3;
	
	.recommendations__row {
		
		.recommendations__title {
			text-align: center;
			font-size: 2rem;
		}
		
		@media (min-width: $screen-sm-min) {
			margin-bottom: 2rem;
			
			.recommendations__title {
				font-size: 3rem;
			}
		}// end desktop-only
	}
	
	
	@media print {
		// no need to see this on a printed piece of paper! :)
		display: none !important;
	}
}

.rec-feed {
	padding: 1rem 0 2rem;
	
	.rec-feed-list {
		.rec-item {
			border-bottom: 2px solid #d2dae5;
			background: #f6f6f6;
			padding: 1em;
			
			// on mobile, show image left of title.
			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: space-between;
			
			.rec-item__img {
				background: #ccc;
				flex-basis: 25%;
				height: 15rem;
				flex-shrink: 0;
				width: 100%;
				height: 12rem;
				overflow: hidden;
				position: relative;
				z-index: 1;
				
				img {
					position: absolute;
					top: 50%;
					left: 0;
					right: 0;
					width: 100%;
					height: auto;
					transform: translateY(-50%);
				}
				@media (min-width: $screen-xs-min) {
					height: 15rem;
				}
				@media (min-width: $screen-lg-min) {
					height: 20rem;
				}
			}
			.rec-item__nonimg {
				flex-basis: 70%;
				margin-top: 1rem;
				@media (min-width: $screen-sm-min) {
					flex-basis: auto;
				}
			}
			.rec-item__title {
				width: 100%;
				font: bold 17px/20px "Lucida Grande", sans-serif;
				color: #333;
			}
			.rec-item__teaser {
				// hidden on mobile.
				display: none;
				
				margin-bottom: 1em;
				font: 12px/18px "Lucida Grande", sans-serif;
				color: #888;
			}
			.rec-item__btn {
				// hidden on mobile.
				display: none;
			}
		}
		
		@media (min-width: $screen-sm-min) {
			// on desktop, they show 3 across.
			display: flex;
			flex-wrap: nowrap;
			.rec-item {
				border: 2px solid #d2dae5;
				width: 32%;
				margin: .5%;
				padding-bottom: 5rem;
				display: inline-block;
				
				position: relative;
				z-index: 5;
				
				.rec-item__teaser {
					display: block;
				}
				.rec-item__info {
					display: flex;
					
					position: absolute;
					bottom: 1rem;
					left: 1rem;
					right: 1rem;
					
					text-align: center;
					
					.rec-item__distance {
						display: block;
						padding: .5em;
						flex-grow: 5;
						align-self: flex-end;
						
						text-align: right;
					}
					.rec-item__btn {
						display: block;
					}
				}
			}
		}
	}
}
.object-fit #container .rec-item__img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transform: none;
	position: static;
}
.rec-item__distance {
	color: #1f8b1f;
}
.rec-item__date {
	float: right;
	width: 45%;
	text-align: right;
}
.rec-item__title a {
	text-decoration: none;
	color: inherit;
}
.rec-item__title a:hover,
.rec-item__title a:active {
	text-decoration: underline;
}
.rec-item__btn {
	background: #142F55;
	display: inline-block;
	padding: .5em 1em;
	font: 14px/24px Arial, sans-serif;
	text-transform: uppercase;
	text-decoration: none;
	color: #fff;
}
.rec-item__btn:hover,
.rec-item__btn:active {
	background: transparent;
	text-decoration: underline;
	color: #222;
}