/*======================================================
** 	   		blue breadcrumb navigation bar         	  **
======================================================== */
.breadcrumbNav {
	background: $brand-primary;
	//border-bottom: 1rem solid $gray-lighter;
	color: $white;

	padding: 1rem 0;

	.breadcrumbNav__nav {
		margin-bottom: 1rem;

		@media (min-width: $screen-md-min) {
			padding-top: .4rem;
			margin-bottom: 0;
		}

		h1,h2,h3,h4,h5,h6,
		.h1,.h2,.h3,.h4,.h5,.h6 {
			margin: 0;

			// use normal font size?
			//text-transform: none;
			color: inherit;
		}
		h1,.h1 {
			// mobile gets smaller headings
			font-size: floor($font-size-h1 * .5);

			@media (min-width: $screen-md-min) {
				// desktop gets mostly full-size headings
				font-size: floor($font-size-h1 * .95);
			}
		}
		h3,.h3 {
			vertical-align: middle;
			// mobile gets smaller headings
			font-size: floor($font-size-h3 * .7);
			font-weight: 400;

			@media (min-width: $screen-md-min) {
				// desktop gets mostly full-size headings
				font-size: floor($font-size-h3 * .95);
			}
		}

		.fa {
			// for whatever reason, the icons don't line up well with an <h1> tag.
			vertical-align: baseline;
			font-size: 1.1em;
			color: $brand-secondary;
		}
		a {
			color: inherit;
		}
	}

	.breadcrumbNav__action {

		> a,
		.btn-outline {
			display: block;
			width: 100%;

			font-weight: 700;
			font-size: 1.6rem;
			text-transform: uppercase;
			color: $brand-secondary;

			.fa {
				margin-left: .5rem;
			}
			@media (min-width: $screen-md-min) {
				width: auto;
			}
		}
		.btn-outline {
			padding-top: 8px;
			padding-bottom: 8px;
		}
	}


	@media (min-width: 1250px) {
		// at very large viewport widths, use the extra room in hopes some of the longer page titles won't wrap.
		.container {
			width: 1230px;
		}
	}
}


/*======================================================
** 			|     special breadcrumb nav print styles       |  		  **
======================================================== */
@media print {
	.breadcrumbNav {
		border-bottom: 0;
		padding: 1rem 0 0;

		.breadcrumbNav__nav {
			width: 100% !important;
		}
		.breadcrumbNav__action {
			display: none !important;
		}
	}
}
