



/*======================================================
** 			|     pagination overrides       |  		  **
======================================================== */
.pagination {
	margin-left: auto;
	margin-right: auto;
}


/*======================================================
** 			|     bootstrap grid overrides       |  		  **
======================================================== */
.row-nogutter {
	margin-left: 0;
	margin-right: 0;
	
	> .container {
		padding-left: 0;
		padding-right: 0;
	}
	.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12,
	.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
	.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
	.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
		padding-left: 0;
		padding-right: 0;
	}
}
.row-equalheight {
	// Muhaha - make the columns STRETCH to be equal height.
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: space-between;
}


/*======================================================
** 	  bootstrap container overrides
** important that the left menu bar
** doesn't make the viewport too small for the content .container
======================================================== */
@media (min-width: $screen-sm-min) and (max-width: ($screen-sm-min + 50)) {
	.container {
		width: 700px;
	}
}
@media (min-width: $screen-md-min) and (max-width: ($screen-md-min + 50)) {
	.container {
		width: 920px;
	}
}


/*======================================================
** 			|     buttons       |  		  **
======================================================== */
.blueButton {
	@extend .btn;
	@extend .btn-primary;
}
.btn.btn-reset {
	// Make a <button> tag "look" just like a link, only with no padding and margin.
    padding: 0 .2em;
    margin: 0;
    border: 1px dashed transparent;
    background: none;
    font: inherit;
    
    &:focus,
    &:hover {
	    background: none;
	    color: inherit;
    }
}
.btn.btn-outline {
	border: 2px solid currentColor;
	background: none;
}
.btn-facebook {
	border-color: currentColor;
	color: $btn-facebook-color;
}
.btn-twitter {
	border-color: currentColor;
	color: $btn-twitter-color;
}