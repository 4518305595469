.owl-carousel {
	img {
		width: 100%;
	}
}



/*======================================================
** 		   CMS PHOTO GALLERY CUSTOMIZATIONS      	  **
======================================================== */

.cmsGallery {
	.photo {
		/*	fullwidth on phone		*/
		width: 100% !important;
		height: 200px !important;
		float: none !important;

		@media (min-width: $screen-sm-min) {
			/*		two-up on large phone/small tablet	*/
			width: 50% !important;
			height: 250px !important;
			float: left !important;
		}
		@media (min-width: $screen-md-min) {
			/*	3-up on desktop		*/
			width: 33% !important;
			height: 250px !important;
		}
		
		.imgDesc h5 {
			font-size: 1.2rem;
		}
	}
}