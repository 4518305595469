$navbar-width-xxs: 90vw;
$navbar-width-xs: 40vw;
$navbar-width-sm: 30rem;
$navbar-padding-xs: 5vw;
$navbar-padding-sm: 5rem;

$screen-tinymenu-max: 670px;

@keyframes delayBlurMenuOpen {
	0%   { filter: blur(0px); }
	99%  { filter: blur(0px); }
	100% { filter: blur(7px); }
}

.mainMenu {
	background-color: rgba(255, 255, 255, 0.7);
	border-right: 1px solid #ddd;
	color: $brand-primary;

	padding: 1rem 0;
	position: fixed;
	z-index: $zindex-navbar;
	bottom: 0px;
	left: 0px;
	// when logged in, make room for the CMS admin bar.
	top: 0;
	.loggedin & {
		top: $admin-bar-height;
	}

	@media (min-width: $screen-sm-min) {
		padding-right: $navbar-padding-sm;
		overflow: hidden;
	}

	// make sure that the width is the width WITHOUT including padding.
	box-sizing: content-box;

	/*
		BEGIN TRANSITIONS
		Note we use will-change to improve performance.
	*/
	transition: .25s width ease-in-out;
	width: 0;
	will-change: padding-right,width;

	.menuOpen & {
		background-color: #fff;

		padding-left: $navbar-padding-xs;
		padding-right: $navbar-padding-xs;
		width: $navbar-width-xs;

		@media (min-width: $screen-sm-min) {
			padding-left: $navbar-padding-sm;
			width: $navbar-width-sm;
		}
		@media (max-width: $screen-tinymenu-max) {
			width: $navbar-width-xxs;
		}
	}


	.mainMenu__logoicon {
		max-width: 3rem;
		position: absolute;
		right: 1rem;
		top: 1rem;
		display: none;
		z-index: 31;
		@media (min-width: $screen-sm-min) {
			display: block;
		}
	}

	// The actual clickable element (on desktop) is a pos-absolute <button> tag which overlays the menu bar.
	// on mobile it just wraps the icon and juts out from the nav.
	.mainMenu__toggle {
		// overlay the menu bar so it's nice and clickable.
		position: absolute;
		z-index: 30;
		right: 0;
		top: 1rem;
		right: -3.9rem;
		.menuOpen & {
			right: 1rem;
		}

		// reset button styles
		background: none;
		padding: 0;
		border: 0;

		@media (min-width: $screen-sm-min) {
			// desktop only!
			// The actual clickable element (on desktop) is a pos-absolute <button> tag which overlays the menu bar.
			bottom: 0;
			top: 0;
			right: 0;
			.menuOpen & {
				right: 0;
			}

			width: $navbar-padding-sm;
			border-left: 1px solid $gray-lighter;
		}

		@media (max-width: $screen-tinymenu-max) {
		}

		&:hover,
		&:active,
		&:focus {
			background: none;
		}

		// This is the menu icon ONLY!
		// The actual clickable element is a pos-absolute <button> tag which overlays the menu bar.
		.navbar-toggle {
			background: $brand-primary;
			color: $brand-secondary;

			margin: 0;

			display: block;
			position: static;

			font-size: 2rem;
			line-height: 1;

			@media (min-width: $screen-sm-min) {
				background: none;
				color: $brand-primary;

				// on desktop we position the icon at the top. Else the icon just stays inside the <button>.
				position: absolute;
				z-index: 20;
				top: 5rem;
				right: .5rem;
			}
		}
	}

	.mainMenu__collapse {
		position: absolute;
		z-index: 10;
		right: $navbar-padding-xs;
		top: 10vh;

		width: $navbar-width-xs;
		@media (max-width: $screen-tinymenu-max) {
			width: $navbar-width-xxs;
		}

		@media (min-width: $screen-sm-min) {
			top: 6vh;
			right: 8rem;
			width: $navbar-width-sm;
		}
		@media (min-width: 1400px) {
			right: 5vw;
		}
		@media (max-width: 550px) {
			width: $navbar-width-xxs;
		}

		// in case it gets too tall for the viewport, we want it to be scrollable.
		max-height: calc(100vh - 6rem);
		overflow-y: auto;

		.mainMenu__logo {
			border: 0;
			width: 73%;
			height: auto;
			margin-bottom: 2rem;
			margin-left: -8px;
			@media (orientation: landscape) {
				// smaller logo
				max-width: 50%;
			}
		}


		.mainMenu__search,
		.mainMenu__menu {
			// the simple search form in the menu popout.
			border-bottom: 2px solid lighten($gray-light,20%);
			margin-bottom: 2rem;

			@media (max-width: $screen-tinymenu-max) {
				font-size: 14px;
				.form-control,
				.btn {
					padding: 8px 12px;
					height: 40px;
				}
			}
		}
		.mainMenu__search {
			.searchform {
				display: flex;
				flex-direction: row;

				.searchform__line {
					padding: 0;
					flex-basis: 80%;
					.form-control {
						border-color: $brand-primary;
					}
				}
				.searchform__line--submit {
					flex-basis: 20%;
				}
			}
		}
		.mainMenu__menu {
			/*
					REMOVED because the Bootstrap nav styles are nicer.
						// The list of links in the menu popout.
						.menu-pulldown {
							ul,
							li {
								margin: 0;
								padding: 0;
								list-style-type: none;

								border-bottom: 1px solid $gray-lighter;

								&:last-of-type {
									border-bottom: 0;
								}
							}
							a {
								padding: 1rem;

								display: block;
								color: inherit;
							}
							ul.level2 {
								display: block;
							}
						}
			*/
		   .navbar-nav {
			   margin: 0;
		    	&,
				& > li {
				    float: none;
				    > a {
					    text-transform: uppercase;
					    font-weight: bold;
					    white-space: nowrap;
				    }
				}
				.open .dropdown-menu {
					// Modify so these are always "mobile-ish" for an off-canvas menu.
				    position: static;
					float: none;
					width: auto;
					margin-top: 0;
					background-color: transparent;
					border: 0;
					box-shadow: none;
			   }
				> li > .dropdown-toggle {
					// make the caret flow to the right!
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
			}
		}

	}



	@media (min-width: $screen-sm-min) {
		// This is the big sideways "MENU" word in the middle of the nav bar.
		// It changes to "CLOSE" when the menu is open.
		&::after {
			content: "MENU";
			transform: rotate(-90deg);
			display: block;

			// middle of nav bar
			position: absolute;
			right: 0;
			top: 46%;
			width: 5rem;

			// fonts
			font-weight: bold;
			font-size: 1.5rem;
			letter-spacing: 1px;

			// It changes to say "CLOSE" when the menu is open.
			.menuOpen & {
				content: "CLOSE";
			}
		}
	}
}
.menuShiftOnOpen {
	// This houses everything EXCEPT the menu popup.
	// It ensures that
	// 1) the page goes "dark" when the menu is open, and
	// 2) clicking off the menu closes the menu.

	@media (min-width: $screen-sm-min) {
		transition: .25s transform ease-in-out;
		// we account for the nav bar even when collapsed.
		position: relative;
		left: 50px;

		// so that nothing on the right gets cut off.
		width: calc(100% - 50px)
	}
	.menuOpen & {
		animation: delayBlurMenuOpen .26s linear;
		filter: blur(7px) saturate(0.7);

		@media (min-width: $screen-sm-min) {
			transform: translateX(calc(#{$navbar-padding-sm} + #{$navbar-width-sm}));
		}/*
		@media (max-width: 550px) {
			transform: translateX($navbar-width-xxs);
		}
*/
	}
}
#container {
	// make sure not to show that ugly horizontal scrollbar!
	overflow-x: hidden;
}

.menuOpen body {
	// don't allow them to scroll while the page is open!
	overflow-y: hidden;
}


/*======================================================
**   all the social links as little yellow icons.     **
======================================================== */
.mainMenu__social,
.footer__social__links__zone {
	text-align: center;

	a {
		background-color: $brand-secondary;
		color: $brand-primary;

		display: inline-block;
		border-radius: 10%;
		padding: 1rem 0;
		width: 4rem;
		height: 4rem;
		margin-bottom: 1rem;

		text-align: center;
		font-size: 2rem;

		.fa {
			font-size: inherit;
			color: inherit;
		}
	}
}





/*======================================================
** 			|     PRINT VIEW       |  		  **
======================================================== */
#container {
	// reset menu transform stuff
	width: 100% !important;
	overflow: hidden !important;
}