/*======================================================
** 			|     historical fact pop-in style       |  		  **
======================================================== */

.featuredHistory {
	border-bottom: .7rem solid #fff;
	background: $brand-primary;
	color: $white;
	
	padding-top: 2rem;
	padding-bottom: 2rem;
	
	.featuredHistory__close {
		border: 1px solid transparent;
		
		position: absolute;
		z-index: 15;
		top: -.5em;
		right: .5em;
		
		font-size: 2.5rem;
		color: $white;
		
		&:hover,
		&:focus {
			border-color: currentColor;
			color: $brand-secondary;
		}
	}
	
	.historicalfact {
		// constrain and center the text
		max-width: 65rem;
		margin: 0 auto;
		text-align: center;
		
		.historicalfact__date {
			// white border underneath heading
			border-bottom: 1px solid $white;
			display: inline-block;
			
			padding: .6rem 1rem;
			margin: 0 0 3rem;
			
			// bla
			font-weight: 400;
			font-size: $font-size-h3;
			line-height: 1.1;
			color: $brand-secondary;
		}
		.historicalfact__desc {
			margin: 0;
			font-size: $font-size-large;
			color: $white;
		}
		a {
			font-size: inherit;
			color: inherit;
		}
		
		padding: 1rem 0;
		@media (min-width: $screen-sm-min) {
			padding: 4.5rem 0;
		}
	}
	
	h1,h2,h3,h4,h5,h6,
	.h1,.h2,.h3,.h4,.h5,.h6 {
		color: inherit;
	}
}

#historicalFacts {
	
	#factsNav {
		margin-bottom: 2rem;
	}
	/*======================================================
	** 			|     historical facts LIST page       |  		  **
	======================================================== */
	.historicalFacts__list {
		@media (min-width: $screen-sm-min) {
			padding: 0 4rem;
		}
		.list-group-item {
		    border: 0;
		    border-bottom: 1px solid $gray-lighter;
		    
	        margin-bottom: 2rem;
		    padding-bottom: 2rem;
		    
		    .date {
				margin-bottom: 1rem;
			}
		}
	}
	/*======================================================
	** 			|     historical facts DETAILS page       |  		  **
	======================================================== */
	.historicalFacts__details {
		@media (min-width: $screen-sm-min) {
			padding: 0 4rem;
		}
		.historicalFacts__details__desc {
			margin-bottom: 4rem;
		}
	}
}
#historicalFacts {
}


/*======================================================
** 			|     printed styles?       |  		  **
======================================================== */