$imgbox-height-tall: 475px;
.main.bikepathlandingpage {
	.main__tricol {
		.bikepathlandingpage__search {
			.imgbox {
				// overrides
				height: $imgbox-height-tall;
				max-height: none;
			}
			h1 {
				margin-bottom: 2rem;
				@media (min-width: $screen-md-min) {
					margin-bottom: 5rem;
				}
			}
		}
		.imgbox {
			&:after {
				// black overlay shows behind text and in front of background image.
				// Makes things way more legible!
				background: rgba(0,0,0,0.5);
				position: absolute;
				z-index: -1;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;

				content: "";
			}

			// height of image button on mobile
			height: floor($imgbox-height-tall / 4);

			@media (min-width: $screen-sm-min) {
				height: floor($imgbox-height-tall / 3);
			}

			.imgbox__text {
				width: 400px;
				max-width: 100%;

				// positioning of textual contents should be front and center!
				margin: 0 auto;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: flex;
				flex-direction: column;
			}

			h2,.h2 {
				font-size: $font-size-h2;
				margin: 0;

				&:after {
					// hide the border
					display: none !important;
				}
			}
		}
	}
}
