/*
	NEWS LIST PAGE
*/
.news.detailMode {
	.newsstory {
		border: .5em solid $gray-lighter;
		padding: 2rem;
		margin-bottom: 2rem;
		
		page-break-inside: avoid;
		
		.storyimage {
			// helps with poor man's object-fit.
			overflow: hidden;
			position: relative;
			z-index: 1;
			height: 15rem;
			margin-bottom: 1rem;
			
			img {
				width: auto;
				height: 100%;
				
				// poor man's object-fit.
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate3d(-50%,-50%,0);
			}
		}
		
		// fonts
		h3 {
			margin: 0;
			
			text-transform: none;
			font-weight: bold;
			font-size: 1.8rem;
			a {
				// dumb <a> tag color.
				color: inherit;
			}
		}
		
		.newsdesc {
			font-size: 1.5rem;
			color: $gray-dark;
		}
		
		.readMore {
			a {
				font-weight: bold;
				font-size: 1.7rem;
				color: $brand-primary;
			}
		}
		
/*
		No, show image above teaser, NOT to the left!
		
		@media (min-width: $screen-md-min), (max-width: $screen-xs-max) {
			margin-bottom: 5rem;
				
			// make the image show next to the text
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			
			.storyimage {
				flex-basis: 30%;
				flex-grow: 1;
			}
			.newsText {
				margin-left: 3%;
				flex-basis: 67%;
				flex-grow: 2;
			}
		}
*/
	}
		
	@media (min-width: $screen-sm-min) {
		// on mobile, go 50/50 width
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;
		
		.newsstory {
			width: 49%;
			margin-bottom: 4rem;
		}
	}
}


/*
	NEWS ARTICLE PAGE
*/
.news.singleMode {
	.newsstory {
		$news-img-width: 31rem;
		
		@media (min-width: $screen-md-min) {			
			// help show image to the left.
			position: relative;
			z-index: 1;
			padding-left: calc(#{$news-img-width} + 1.5rem);
			
			.storyimage {
				// image (on desktop) should show to the left.
				position: absolute;
				left: 0;
				top: 0;
				width: $news-img-width;
				height: auto;
			}
		}
		
		h2 {
			margin-top: 0;
		}
	}
	
	#newsfeatures {
		// "news features" are the print button and "back" button
		a {
			text-transform: uppercase;
			color: $brand-primary;
		}
	}
}