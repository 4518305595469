.banner {
	// make sure we leave room for the fixed header!
	// even if the banner is empty.
	// 	min-height: $header-height;
	
	.flexslider {
		padding: 0;
		border: 0;
		box-shadow: 0;
		border-radius: 0;
	}
	
	img {
		// images should fill the width of the banner
		width: 100%;
		height: auto;
	}
	
	
	.map {
		// map wrapper
		
		// contain positioning for the map and gps button
		position: relative;
		z-index: 2;
		
		#map-canvas {
			position: relative;
			z-index: 10;

			height: 30rem;
			@media (min-width: 500px) {
				height: 40rem;
			}
			@media (min-width: $screen-sm-min) {
				// The map inside the banner should be tall!
				height: 50vh;
			}
		}
		
		
		#gpsBtn {
			/*
			 * GPSME button.
			 * Click the button to toggle your location on the map.
			 * @cite assets/js/_gpsme.js.
			*/
			
			// first, we hide it for everyone except the BCG office IP.
			display: none;
			
			// override styling
			border: 0;
			background: none;
			box-shadow: none;
			
			// override button appearance
			-webkit-appearance: none;
			-moz-appearance: none;
			-o-appearance: none;
			appearance: none;
			
			// overlay the top left corner of the map
			position: absolute;
			z-index: 100;
			top: 1rem;
			right: 2rem;
			
			// text
			font-size: 1.8rem;
			text-shadow: 2px 2px 2px rgb(255, 255, 255);
			
			.fa {
				// give it some room, fellas!
				display: inline-block;
				padding: .4em;
				
				// blue icon over white background
				background: #fff;
				color: $brand-primary;
				
				// slightly larger than parent font size.
				font-size: 1.3em;
			}
		}
		/*
		
		#page-heading .gps {
			display: flex;
			flex-direction: row;
			align-items: center;
		
			position: absolute;
			top: .5em;
			right: .5em;
			color: inherit;
		}
		.gps__err {
			margin-right: 1em;
		}
		.geo-callback--decline {
			border: 1px solid #a46060;
			color: red;
			padding: 0 .5em;
			background: #682828;
		}
		.gps__text {
			margin-right: .4em;
		}
		.gps__icon__text,
		.gps__icon {
			display: inline-block;
			vertical-align: middle;
		}
		.gps__icon {
			border-radius: 100%;
			background-color: #777;
			height: .7em;
			width: .7em;
			margin: .35em;
		}
		.gps__icon--on {
			background-color: greenyellow;
		}
		.gps__icon__text {
			color: #fff;
		}
		.gps__togglebtn {
			display: block;
			padding: .5em;
		}
		*/
	}
	
	/*======================================================
	** 			|     custom styles for print       |  		  **
	======================================================== */
	@media print {
		.map {
			#map-canvas {
				height: 30rem;
				.gm-style-iw {
					display: none !important;
				}
			}
			#gpsBtn {
				display: none !important;
			}
		}
	}
}