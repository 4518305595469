.owl-carousel {
	img {
		width: 100%;
	}
	.owl-nav {
		padding: 5px;
		text-align: right;
		.owl-prev,
		.owl-next {
			background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAABACAYAAABY1SR7AAAAsUlEQVRoge3YQQ2EMBBA0ZGAFCQgBSnrBClIQMpK+HvYJkvInuDQzuQ/BfOTlpZGSJIkSZLGAWzA3HuOR4AXX+/UMcAEHFVjlt4z3dZidn7W3jM90ja+McO5xGy953kEWI0Z0SVmB6beM90GLO2MoZ05qWPmMjEREadldmS/0uQOKbG0Smz2Ep/fEgdilYj8l0YqXOPTR1DhV5cKjw9/IvJdOSLqPdBtqSMkSZIkSRrHB6kFucUGIHJoAAAAAElFTkSuQmCC') no-repeat center #000;
			background-color: rgba(0, 0, 0, 0.4);
			padding: 5px;
			display: inline-block;
			font-size: 0;
			width: 30px;
			height: 30px;
			margin: 0 2px 0 0;
			cursor: pointer;

			&.disabled,
			&.disabled {
			    background-color: rgba(0, 0, 0, 0.2);
			    cursor: default;
			}
		}
		.owl-prev {
			-webkit-transform: rotate(180deg);
			-moz-transform: rotate(180deg);
			-ms-transform: rotate(180deg);
			-o-transform: rotate(180deg);
			transform: rotate(180deg);
			right: 20px;
		}
	}
}