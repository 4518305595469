h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
	text-transform: uppercase;
	font-weight: 700;
	color: $brand-primary;
}
h5, h6,
.h5, .h6 {
	color: $gray-light;
}

@media (max-width: $screen-xs-max) {
	// shrink headings by 1/3
	h1,.h1 {
		font-size: floor($font-size-h1 * .7);
	}
	h2,.h2 {
		font-size: floor($font-size-h2 * .7);
	}
	h3,.h3 {
		font-size: floor($font-size-h3 * .7);
	}
}