
/*======================================================
** 	   app-like notification bar, designed for online/offline messages.   **
======================================================== */
#app-notif {
	background: $white;
	color: $brand-secondary;
	
	padding: 1rem;
	margin: 0;
	
	// pin to bottom of viewport
	position: fixed;
	z-index: 200;
	bottom: 0;
	left: 0;
	right: 0;
	.alert {
		padding: 0;
		margin: 0;

		.media-heading {
			// inherit the color from the "level", like danger=red or success=green.
			color: inherit;
		}
	}
}