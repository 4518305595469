/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "variables";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

// Reset and dependencies
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
//@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/print";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

// Core CSS
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/buttons";

// This removed to allow custom form styles
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms";

// Custom bootstrap overrides
@import "forms";

// Components
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navbar";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pager";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/labels";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/badges";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/alerts";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/media";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/list-group";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/panels";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/wells";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/close";

// Custom
@import "typography";

// Components w/ JavaScript
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/modals";

// Utility classes
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";

// Main content layout
@import "header";
@import "homebanner";
@import "menu";
@import "banner";
@import "breadcrumbBar";
@import "previous-page";
@import "main";
@import "bikepathpage";
@import "featuredhistory";
@import "subscribe";
@import "recommendedforyou";
@import "socialside";
@import "footer";
@import "gallery";

// more Bootstrap overrides
@import "tabbednav";
@import "global";

// CMS pages
@import "news";
@import "events";

// Custom programming content
@import "itineraries";
@import "bikepaths";
@import "destinations";
@import "owlCarousel";
@import "mypath";

// make it app-like
@import "appify";

// Print
@import "print";
