.socialSide {
	background: $brand-primary;
	color: $white;

	// anchor to bottom of device
	position: fixed;
	z-index: 25;
	bottom: 0;
	left: 0;
	right: 0;

	@media (min-width: $screen-sm-min) {
		// anchor to right side of device
		left: auto;
		width: 45px;
		right: 0;
		bottom: 2vh;
		//transform: translateY(30%);
	}
	.socialSide__wrap {
		.shareBtns {
			@media (max-width: $screen-xs-max) {
				// on mobile, show the word "SHARE" next to the icons
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;

				&:before {
					content: "SHARE";
					display: inline-block;
					vertical-align: middle;
					text-align: center;
					width: 20%;
				}
				.share {
					width: 80%;
				}
			}
		}
		.share {
			@media (max-width: $screen-xs-max) {
				// on mobile (only!) stretch the icons left-to-right
				display: inline-flex;
				flex-direction: row;
			}

			li {
				width: 100%;

				a {
					background: none;
					display: block;
					padding: .5em .3em;
					text-align: center;
					font-size: 2.2rem;
					color: inherit;

					&:hover,
					&:focus {
						background-color: $brand-secondary;
						text-decoration: none;
					}
				}
			}
		}
	}


	/*======================================================
	** 			|     print styles       |  		  **
	======================================================== */
	@media print {
		// duh, just hide. no social sharing from printed page.
		display: none !important;
	}
}
