.footer {
	background-color: $brand-primary;
	color: $white;

	// pad the footer
	padding-top: 2rem;
	padding-bottom: 2rem;
	@media (max-width: $screen-xs-max) {
		// pad footer to make room for social sharing bar.
		padding-bottom: 8rem;
	}


	// center text in mobile
	text-align: center;

	@media (min-width: $screen-sm-min) {

		text-align: left;

		.footer__copyright__authors {
			text-align: right;
		}
	}

	// menu in footer
	.footer__menu {
		@media (min-width: $screen-sm-min) {
			margin-bottom: 1rem;
		}

		ul,li {
			display: block;
			margin: 0;
			padding: 0;
		}
		ul.level1 {
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}
		li {
			padding: 1rem;
			&:first-of-type {
				padding-left: 0;
			}
			&:last-of-type {
				padding-right: 0;
			}
			a {
				text-transform: uppercase;
				font-weight: bold;
				font-size: 2rem;
			}
		}
	}

	// social icons in footer
	.footer__social {
		@media (min-width: $screen-sm-min) {
			margin-bottom: 1rem;
		}
		.footer__social__links {
			@media (max-width: $screen-xs-max) {
				&:before {
					content: "FOLLOW US";
					display: block;
					padding-bottom: 1rem;
				}
			}
			.footer__social__links__zone {
				text-align: center;

				@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
					width: 18rem;
					margin: 0 auto;
				}
				@media (min-width: $screen-md-min) {
					text-align: left;
					padding: 2rem 0;
				}
				p {
					margin: 0;
				}
				a {
					@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
						margin: .6rem;
					}
					// somehow the top and bottom offsets are different on these footer icons, so this makes them look vertically centered.
					line-height: 1.3;
					color: $brand-primary;
				}
			}
			.btn-link {
				margin-top: 1rem;
				padding: 0 .1em;

				border-bottom: 1px solid currentColor;
				color: inherit;
			}
			.btn-link:hover,
			.btn-link:focus {
				text-decoration: none;
				border-bottom-color: $brand-secondary;
				color: $brand-secondary;
			}
		}
		.footer__social__logo {
			text-align: center;
		}
		.footer__social__iheartny {
			text-align: right;
			@media (max-width: $screen-sm-max) {
				text-align: center;
			}
		}
	}

	a {
		color: inherit;
		&:hover,
		&:focus {
			color: $brand-secondary;
		}
	}
	.footer__copyright {
		.footer__copyright__links {
			a {
				text-transform: uppercase;
				text-decoration: none;
				color: inherit;
			}
		}
		.footer__copyright__authors {
			p {
				margin: 0;
				line-height: 1.6;
				font-size: .9em;
			}
			a {
				font-weight: bold;
			}
		}
	}


	@media print {
		// no need to see this on a printed piece of paper! :)
		display: none !important;
	}
}

.back-to-top {
	position: fixed;
	bottom: 10px;
	z-index: 99;
	left: 50%;
	transform: translateX(-50%);

	a {
		padding: 1.06em 1.6rem 1.7rem;
		//border-radius: 50%;
		background-color: $brand-primary;
		//display: block;
		box-shadow: 0 0 1px rgba($white, .6);
		transition: all .3s;
		height: 45px;
		width: 90px;
		border-radius: 90px 90px 0 0;

		&:hover {
			background-color: lighten($brand-primary, 5%);
			box-shadow: 0 0 6px rgba($white, .6);
		}
	}

	@media (max-width: $screen-xs-max) {
		display: none !important;
	}
}
