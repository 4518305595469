$screen-very-small-min: 450px;
.subscribe {
	
	
	&.popout {
		display: block !important;
		
		/*======================================================
		** 			|     Make this a popup       |  		  **
		======================================================== */
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		
		// dark-ish background
		background: rgba(0, 0, 0, 0.84);
		
		// override jquery slide
		height: auto !important;
		
		.container {
			width: 400px;
			max-width: 95vw;
		}
		
		.subscribe__wrap {
			position: absolute;
			z-index: 1;
			// center it, I guess
			top: 50%;
			left: 50%;
			transform: translate3d(-50%,-50%,0);
			
			// 
			background: $brand-primary;
			
			padding: 2rem;
			
			@media (min-width: $screen-sm-min) {
				padding: 3rem;
				border: 1rem solid #ffffff4d;
			}
			
			.subscribe__close {
				// only shows in the popup!
				display: block;
				border: 1px solid transparent;
		
				position: absolute;
				z-index: 15;
				top: 0;
				right: 0;
				
				font-size: 2rem;
				color: $white;
				
				&:hover,
				&:focus {
					border-color: currentColor;
					background: $white;
					color: $brand-secondary;
				}
			}
			
			.newsletterSubscribeForm {
				// the form
				display: block;
				
				.fm-line {
					flex-basis: 100%;
					margin-bottom: 2rem;
				}
				
				
				// yellow button on blue background
				.btn-primary {
					background-color: $brand-secondary;
					border: .2rem solid $brand-secondary;
					
					text-transform: uppercase;
					
					&:hover,
					&:focus {
						background-color: $brand-primary;
					}
				}
			}
		}
	}
	
	
	
	
	background-color: $brand-secondary;
	color: $white;
	
	h1,h2,h3,h4,h5,h6,
	.h1,.h2,.h3,.h4,.h5,.h6 {
		color: inherit;
	}
	
	@media (min-width: $screen-sm-min) {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
	
	.subscribe__wrap {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
	
	.subscribe__close {
		// only show this in the popup!
		display: none;
	}
	
	.newsletterSubscribeForm {
		@media (min-width: $screen-very-small-min) {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: flex-end;
			justify-content: space-between;
		}
		
		.fm-line {
			label {
				// labels should overlay the fields?
			}
			input {
				@extend .form-control;
				// newsletter form inputs should not have a border 
				border: 0;
			}/*
				UPDATE: the firstname/lastname fields are no longer used.
			&:nth-of-type(2),
			&:nth-of-type(3) {
				// first two REAL fields (firstname and lastname) show left-to-right
				flex-basis: 48%;
			}
*/
			&:nth-of-type(2) {
				// email field should be pretty wide
				flex-basis: 100%;
			}
			&:nth-of-type(3),
			&:nth-of-type(4) {
				flex-basis: 100%;
				@media (min-width: $screen-sm-min) {
					flex-basis: 47%;
				}
				margin-top: 1rem;
			}
			.required {
				// Should be obvious that email is required in a newsletter form!
				display: none;
			}
		}
		.btn-primary {
			// on mobile, should be 100% width.
			width: 100%;
			display: block;
			margin-top: 1rem;
		}
	}
	
	
	@media print {
		// no need to join mailing list from a printed piece of paper! :)
		display: none !important;
	}
}