/*======================================================
** 			|     basic layout       |  		  **
======================================================== */
.mypath__header {
	border-bottom: 2px solid $brand-secondary;
	margin-bottom: 2rem;
	padding-bottom: 2rem;
}

/*======================================================
** 	   Account/profile page on /mypath/account       **
======================================================== */
.profileForm {
	.profileForm__img {
		margin-bottom: 2rem;
	}
	.profileForm__upload {
		position: relative;
		z-index: 1;
		
		.profileForm__upload__btn {
			display: block;
			position: absolute;
			z-index: 0;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
		.profileForm__upload__fineupload {
			min-height: 40px;
			position: relative;
			// Important that this shows in front of the button so it's clickable!
			z-index: 20;
			label,
			.qq-upload-success {
				// Move your issues off the road!
				position: absolute;
				left: -999px;
			}
			.qq-upload-button {
				// fill the button width so it's nice and clickable!
				width: 100%;
			}
		}
	}
}


/*======================================================
** 	 how should a path look when it's a favorite?     **
======================================================== */
.favoriteIcon {
	 // overlays the image
	 position: absolute;
	top: 1rem;
	right: 1rem;
	line-height: 1;
	width: 4rem;
	height: 4rem;
	.fa {
		text-shadow: 1px 2px 6px rgba(0,0,0,0.4);
		font-size: 3rem;
		color: #fff;
		
		&:hover,
		&:focus {
			color: $brand-danger;
		}
	}
	&.isFavorite .fa {
		color: $brand-danger;
	}
}

/*======================================================
** 	    progress bar on main /mypath page       	  **
======================================================== */
.progressBar {
	.progressBar__label,
	.progressBar__toptier {
		@extend .h4;
		margin: 0;
	}
	.progressBar__toptier {
		&.progressBar__toptier--disabled {
			color: $gray-light;
		}
	}
}


/*======================================================
** 	   login page at    	  **
======================================================== */

.login__wrap {
	margin-top: 2rem;
	margin-bottom: 5rem;
	
	.login__wrap__auth0 {
		margin-top: 5rem;
		text-align: center;
		
		.btn-primary {
			display: inline-block;
			width: 21rem;
		}
	}
}