.homebanner {
	// OR source.unsplash.com/g3O4mgRw7UI
	/*background: url('https://source.unsplash.com/UbmYvvNt1xY/700x700') no-repeat center center /cover transparent;
	@media (min-width: $screen-sm-min) {
		background-image: url('https://source.unsplash.com/UbmYvvNt1xY/1000x1000');
	}
	@media (min-width: $screen-md-min) {
		background-image: url('https://source.unsplash.com/UbmYvvNt1xY/1400x1400');
	}
	@media (min-width: $screen-lg-min) {
		background-image: url('https://source.unsplash.com/UbmYvvNt1xY/2400x2400');
	}*/

	// messing around
	background-blend-mode: multiply;
	background-color: rgba(0,0,0,0.2);

	color: $white;

	min-height: 20rem;

	overflow: hidden;

	// mobile
	padding: 10rem 0;


	position: relative;
	z-index: 1;

	@media (min-width: $screen-sm-min) {
		padding: 10vh 0 15vh;
	}

	.homebanner__cta {
		position: absolute;
		z-index: 20;
		top: 2rem;
		right: 3rem;
	}
	.homebanner__logo {
		// center the logo
		text-align: center;

		margin-bottom: 5rem;
		@media (min-width: $screen-md-min) {
			margin-bottom: 7rem;
		}

		img {
			max-width: 200px;
			height: auto;
			@media (min-width: $screen-sm-min) {
				max-width: 250px;
			}
			@media (min-width: $screen-md-min) {
				max-width: 350px;
			}
		}
	}

	.homebanner__intro {
		text-align: center;
		max-width: 60rem;
		margin: 0 auto 5rem;
		@media (min-width: $screen-sm-min) {
			margin-bottom: 0;
		}
		h1 {
			margin-top: 0;
			@media (min-width: $screen-sm-min) {
				font-size: floor($font-size-h1 * 1.65);
			}
		}
		h2 {
			display: none;
			@media (min-width: $screen-sm-min) {
				font-weight: 400;
				display: block;
			}
		}
	}
	.homebanner__downlink {
		background: #fff;
		color: $brand-primary;
		text-decoration: none;

		// center bottom of banner
		position: absolute;
		z-index: 10;
		left: 50%;
		bottom: 5rem;
		margin-left: -.5em;

		// white circle around caret down arrow
		border-radius: 100%;
		font-size: 3rem;
		height: 1.6em;
		width: 1.6em;
		line-height: 1.4;
		text-align: center;

		//Down arrow
		i,span {
			display: block;
			padding-top: 8px;
		}
	}
	h1,h2,h3,h4,h5,h6,
	.h1,.h2,.h3,.h4,.h5,.h6 {
		color: inherit;
	}
	
	.homebanner__herovideo__container {
		
	}

	.homebanner__herovideo {
		
		background-color: $gray-base;
		position: absolute;
		//top: 0;
		//bottom: 0;
		//left: 0;
		//right: 0;
		width: auto;
		height: auto;
		@media (min-width: $screen-md-min) {
			//width: 100%;
		}
		z-index: -1;
		
		left: 50%;
		min-height: 100%;
		min-width: 100%;
		top: 50%;
		transform: translate(-50%, -50%);

		
		//object-fit: cover;
		//object-position: top;
	}
}
