/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */

// ==========================================================================
// Print styles.
// Inlined to avoid the additional HTTP request: h5bp.com/r
// ==========================================================================

@media print {
	@page {
		size: auto;
		margin: 10mm 10mm;
	}
	
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important; // Black prints faster: h5bp.com/s
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }
/*

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }
*/

    // Don't show links that are fragment identifiers,
    // or use the `javascript:` pseudo protocol
/*
    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }
*/

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; // h5bp.com/t
    }

    tr,
    img {
        page-break-inside: avoid;
    }
/*
	
	This messes up google maps!!
    img {
        max-width: 100% !important;
    }
*/

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    // Bootstrap specific changes start

    // Bootstrap components
    .navbar {
        display: none;
    }
    .btn,
    .dropup > .btn {
        > .caret {
            border-top-color: #000 !important;
        }
    }
    .label {
        border: 1px solid #000;
    }

    .table {
        border-collapse: collapse !important;

        td,
        th {
            background-color: #fff !important;
        }
    }
    .table-bordered {
        th,
        td {
            border: 1px solid #ddd !important;
        }
    }

    // Bootstrap specific changes end
    // hide CMS toolbar in print.
    body #toolbar-administration {
	    display: none !important;
    }
    
    /*======================================================
    ** 			|     layout changes for print       |  		  **
    ======================================================== */
    #mainMenu {
	    display: none !important;
    }
    .menuShiftOnOpen {
	    transform: none !important;
	    filter: none !important;
	    width: 100% !important;
	    position: static;
	    left: 0 !important;
    }
    .container {
	    width: 100%;
    }
    
    
    /*======================================================
    ** 			|     typography tweaks       |  		  **
    ======================================================== */
    h1 {
	    // not so big!
	    font-size: 3rem;
    }
    h2 {
	    // not so big!
	    font-size: 2.5rem;
    }
}
