.header {
	$header-padding-top: 1rem;
	border-bottom: .3em solid $brand-secondary;
	background-color: $white;
	color: $brand-primary;

	height: calc($header-height - $header-padding-top);
	padding-top: $header-padding-top;
	padding-bottom: .5rem;

	// useful for absolute positioning of button.
	position: relative;
	z-index: 500;

	@media (min-width: $screen-sm-min) {
		padding-top: 0;
		padding-bottom: 0;
		border-bottom-color: transparent;

		// fancy
// 		transition: .2s background-color ease-in, .2s border-bottom-color ease-in;

		// EXPIRIMENT
		background-color: rgba($white,.5);
		.homepage & {
			// avoid transparency because it's already baked into the homepage design.
			background-color: rgba($white,0);
		}/*

		position: fixed;
		left: 0;
		right: 0;

		&.header--scrolled {
			border-bottom-color: $brand-secondary;
			background-color: rgba($white,.9);
		}
*/
	}

	.header__row {
		// use flexbox to center the menu button.
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		align-items: center;

		.header__row__logo {
			text-align: center;

			body.subpage-landingbike & {
				@media (min-width: $screen-sm-min) {
					text-align: left;
				}
			}

			img {
				// very small/short image!
				max-height: 76px;
				width: auto;
				height: auto;
			}

			.bike-history-logo {
				display: inline-block;

				img {
					@media (min-width: $screen-sm-min) {
						margin-top: -1.5rem;
						margin-bottom: -1.5rem;
						max-height: 10.5rem;
					}
				}
			}
		}

		.header__row__other {
			text-align: right;
		}
	}
	.navbar-toggle {
		background: $brand-primary;
		color: $white;

		// at top left, near menu slideout
		position: absolute;
		z-index: 20;
		left: 0;
		top: 2rem;

		line-height: 1;
		font-size: 2.5rem;
		margin: 0;
		// shows all the time, not just on mobile!
		display: block;
	}

	@media (min-width: $screen-sm-min) {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
	
	
	
	/*======================================================
	** 			|     PRINT TWEAKS       |  		  **
	======================================================== */
	@media print {
		.header__row__other {
			display: none;
		}
	}
}

.i-love-ny {
	display: inline-block;
	vertical-align: middle;
	margin-left: 1rem;

	svg, img {
		max-width: 120px;
	}

	@media (max-width: $screen-xs-max) {
		display: none;
	}
}
