.homeitins {
	background: $brand-primary;
	color: $white;
	
	h2 {
		color: $brand-secondary;
	}
	.homeitins__list {
		
	}
}

.featuredItins {
	// This is for the list of "featured" itineraries on the homepage.
	// In this case, "featured" means all!
	
	@media (min-width: $screen-sm-min) {		
		// show left-to-right, 50/50 width.
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
	}
	
	.featuredItins__itin {
		// wrap the absolute background image.
		position: relative;
		overflow: hidden;
		
		padding: 3rem;
		text-align: center;
		
		// white borders around itinerary boxes
		border-bottom: 2px solid $white;
		
		@media (min-width: $screen-sm-min) {
			height: 20rem;
			
			&:nth-of-type(2n) {
				// left border only for the right-side boxes
				border-left: 2px solid $white;
			}
			
			// take up half width
			flex-basis: 50%;
			
			display: flex;
			flex-direction: column;
			justify-content: center;
/*
			Transition is dumb.
			@media (min-width: $screen-sm-min) {
				// fancy transition - make the text animate downwards
				transition: transform .1s ease-in-out;
			}
			@media (min-width: $screen-md-min) {
				font-size: $font-size-h1;
			}
			transform: translateX(0.01rem);
			&:hover .featuredItins__itin__title {
				transform: translateX(-0.8rem);
			}
*/
		}
		
		&:hover .featuredItins__itin__img img {
			// darker on hover
			filter: brightness(.4);
		}
		
		.featuredItins__itin__img {
			// acts as a background image.
			overflow: hidden;
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			img {
				filter: brightness(.6);
				object-fit: cover;
				width: 100%;
				height: 100%;
				
				// darker on hover
				transition: .2s filter ease-in-out;
			}
		}
		.featuredItins__itin__overlay {
			color: $white;
			
			// make sure it shows over the image!
			position: relative;
			z-index: 3;
			
			// nicely wrap and center the text.
			max-width: 450px;
			margin: 0 auto;
			
			.featuredItins__itin__title,
			.featuredItins__itin__teaser {
				text-shadow: 2px 0 3px rgba(0,0,0,0.4);
				color: inherit;
			}
			@media (min-width: $screen-md-min) {
				.featuredItins__itin__title {
					font-size: $font-size-h2;
					font-weight: 400;
				}
			}
			.featuredItins__itin__teaser {
				display: none;
			}
			
			h3, p, a {
				margin: 0;
				line-height: 1.1;
				text-decoration: none;
				color: inherit;
			}
			.fa {
				color: $brand-secondary;
			}
		}
		@media (min-width: $screen-sm-min) {
			a:hover,
			a:focus {
				outline: none;
				text-decoration: none;
			}
		}
	}
}