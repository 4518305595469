/*======================================================
** 			 styles for NEW bike path system.		 		**
======================================================== */

$screen-xxs-max: 450px;


/*	intro stuff for a route	*/
.panel--intro {
	box-shadow: none;
	padding: 0 1rem;
}
/*

.panel--intro>h1 {
	background: $brand-primary;
	color: #fff;
	padding: .3em;
	margin: 0 -1rem;
	line-height: 1;
}

.bikepath__intro__teaser {
	margin-bottom: 1em;
}
*/




/*======================================================
** 			|		 BIKE PATH SEARCH FORM			 |				**
======================================================== */

.pathSearch {

	.main__tricol & {
		// for the /bike-thru-history landing page...
		// this search form shows inside a .imgbox div. See _main.scss

		text-align: left;
		.search__difficulty {
			display: none;
		}
	}
}

.diff {
	padding-left: .5em;
	margin-bottom: .7em;
	margin-right: .5em;
}


/*======================================================
** 	 difficulty icons (based on the Born Difficulty Algorithm)	 **
======================================================== */
.diff__icon {
	transition: .3s color;
	display: inline-block;
	vertical-align: middle;
	position: relative;
	top: -3px;
	width: 1.2rem;
	height: 1.2rem;
	margin-left: .3em;
	margin-right: .6em;
	/*	the green/red/black colors*/
	&.diff__icon--hard {
		background-color: transparent;
		width: 0;
		height: 0;
		padding: 0;
		border-bottom: 17px solid #c22216;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-top: 0;
	}
	&.diff__icon--easy {
		border-radius: 1em;
		background-color: #009c4e;
	}

	&.diff__icon--medium {
		background-color: #006f9e;
	}
}
/*	sidebar county menu	*/

.bikepath__side,
.bikepath__search {
	background: #EBF0F6;
	padding-bottom: 1em;
	padding-top: 2rem;
}
/*

.menu-simplevert, .menu-simplevert ul, .menu-simplevert li {
	display: block;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.menu-simplevert li {
	margin: 10px 0;
}

.menu-simplevert li>a {
	background: #fff;
	border: 2px solid currentColor;
	color: #008ab0;
	border-radius: 5px;
	display: block;
	padding: .4em;
	margin: 0;
	font: 700 1rem/1em "Lato", Verdana, sans-serif;
	text-align: center;
}
*/


/*

.menu-simplevert h4 {
	background: ;
}
*/
/*

.menu-simplevert .active a {
	background: #008ab0;
	border-color: transparent;
	color: #fff;
}

.menu-simplevert .active a:hover, .menu-simplevert .active a:focus {
	background: #fff;
	color: #008ab0;
}

.menu-simplevert a:hover, .menu-simplevert a:focus {
	color: #008ab0;
	background: #fff;
}
*/


/*	controls to adjust the results per page	*/


.controls {
	margin-bottom: 1rem;
}

.controls__sortform, .controls__perpage {
	text-align: center;
}

.controls__sortform label {
	font-weight: bold;
}

.controls__sortform select {
	min-width: 200px;
}
.controls__perpage>.pagination {
	margin: 0 auto;
}
/*======================================================
** 	the BIKE PATHS listed on the /bikepaths page.		 **
======================================================== */
#container .listing-route-name {
	padding: .2em 0 .1em;
	font-size: 30px;
}

.listing .listing-route-name {
	background: $brand-primary;
	color: #fff;
	margin-bottom: 10px;
}

.listingDescNum {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	top: -4px;
	border-radius: 100%;
	border: 1px solid #ffb814;
	margin: 0 .2em;
	width: 1.3em;
	height: 1.3em;
	text-align: center;
	line-height: 1.4em;
	font-size: .8em;
}

.bikepath {
	position: relative;

	/*======================================================
	** 		The listings of destinations ON a bike path **
	======================================================== */
	.destination__list__item {
		.listingNum {
			/* 	background-color: #EBF0F6; */
			font-size: 1.2rem;
			color: #134071;
			display: none;
			@media print {
				display: none;
			}
			@media (min-width: $screen-sm-min) {
				display: flex;
				flex-direction: column;
				justify-content: center;
				text-align: center;
			}
		}
		.squarefit {
			overflow: hidden;
			position: relative;
			width: 100%;
			min-height: 115px;
			height: 100%;
			img.squarefit__img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate3d(-50%, -50%, 0);
				width: auto;
				height: auto;
				max-width: 100%;
			}
		}

		.destination__list__item__desc {
			flex-grow: 2;

			.listingDesc {
				height: auto;
				min-height: 0;
				.destination__list__item__desc__title {
					margin: 0;
					padding: 0;
					text-transform: none;
					font-size: 2.2rem;
					color: $gray-light;
					a {
						text-decoration: none;
						cursor: pointer;
						color: inherit;
					}
				}
			}
			.listingMore {
				color: #607890;
				a, button {
					color: inherit;
				}
				@media print {
					display: none;
				}
			}
		}
		&:not(:last-of-type) {
			border-bottom: .2em $brand-primary-light solid;
		}
	}
	/*======================================================
	** 			|		 downloads in sidebar			 |				**
	======================================================== */

	.bikepath__downloads p {
		text-align: center;
	}

	/*======================================================
	** 			|		 bikepath main details page			 |				**
	======================================================== */
	.bikepath__intro__statsbar {
		margin-bottom: 1em;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		font-weight: 700;
		font-size: 1.4rem;
		color: #515151;
		.h5 {
			font-size: inherit;
		}

		@media (min-width: $screen-sm-min) {
			font-size: 1.8rem;
			.bikepath__returnlink {
				display: none;
			}
		}
		@media (max-width: $screen-xxs-max) {
			flex-direction: column;
			font-size: 1.7rem;
		}
	}

	.bikepath__subtitle {
		margin: 0;
		padding: 0;
		align-self: flex-start;
		font-size: 1.7rem;
		color: #102e56;
	}

	.bikepath__returnlink {
		align-self: flex-end;
		text-decoration: underline;
		font-weight: 900;
	}

	.bikepath__returnlink, .bikepath__returnlink:link, .bikepath__returnlink:visited, .bikepath__returnlink:active, .bikepath__returnlink:hover, .bikepath__returnlink:focus {
		color: inherit;
	}

	.bikepath__returnlink .glyphicon {
		margin-right: .3em;
		font-size: .8em;
	}
	.bikepath__otherbar {
		border-bottom: 3px solid $brand-primary-light;
		font-size: 1.2em;
		padding: .3em 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		color: $brand-primary;

		@media (max-width: 500px) {
			text-align: center;
			flex-direction: column;
			padding-top: 0;
			padding-bottom: 1em;
		}
		.bikepath__otherbar__heading {
			margin: 0;
			font-weight: 700;
			font-size: 25px;
			text-transform: none;
			text-align: center;
			color: $brand-primary;
		}
	}
	.bikepath__map {
		// google maps static map image overlay
		background: rgba(0,0,0,0.5);

		//
		position: fixed;
		z-index: 1500;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		// hide until proven shown... or something.
		display: none;

		.bikepath__map__inner {
			height: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;


			// the google static map image
			.bikepath__map__content {
				background: $white;
				border: 2px solid $brand-primary;

				padding: 2rem;
				width: 500px;

				// positioning for the button
				position: relative;

				// close the popup
				.bikepath__map__close {
					position: absolute;
					z-index: 20;
					top: 0;
					right: 0;

					background: $white;
					width: 2em;
					height: 2em;
					padding: .5rem;
					font-size: 2.4rem;
					color: $brand-primary;
				}

				.bikepath__map__title {
					margin-top: 0;
					padding-right: 3rem;
				}
				.bikepath__map__img {
					width: 100%;
					height: auto;

					img {
						width: 100%;
						// center the image!
						display: block;
						margin: 0 auto;
					}
				}
			}
		}
	}


	/*======================================================
	** 			|		 directions in sidebar			 |				**
	======================================================== */
	/*
	.bikepath__mapbtn {
		margin-top: .7em;
		padding: 0;
	}
	*/
	.bikepath__directions {
		width: 100%;
		margin-bottom: 1.5em;

		@media (min-width: $screen-md-min) {
			max-height: 500px;
			overflow-y: auto;
		}

		// used for positioning the static map image.
		position: relative;

		.directions__direction {
			position: relative;

			// large on mobile
			padding: 1em;
			padding-left: 5rem;
			padding-right: 9rem;
			font-size: 2rem;

			@media (min-width: $screen-sm-min) {
				// compact on desktop
				padding-top: 1.2em;
				padding-right: 6rem;
				padding-bottom: 1.2em;

				font-size: 1.2rem;
			}
			color: inherit;

			&:not(:last-of-type) {
				border-bottom: 2px $brand-primary-light solid;
			}

			&:focus {
				background-color: #134071;
				color: #fff;
				.directions__direction__distance__unit {
					color: #9ff1b8;
				}
			}

			.directions__direction__sign {
				transform: translateY(-50%);
				position: absolute;
				top: 50%;
				left: .25em;
				width: 30px;
				text-align: center;
				font-size: 30px;
				.directions__direction__sign img {
					max-height: 25px;
				}
			}
			.directions__direction__distance {
				transform: translateY(-50%);
				position: absolute;
				z-index: 2;
				top: 50%;
				right: .5em;

				.directions__direction__distance__unit {
					color: #108f36;
				}
			}
		}
	}




	:focus .directions__direction__sign {
		color: #fdb916;
	}

}



/*======================================================
** 	 slightly different destination list styles for bike path page.	 **
======================================================== */
.dest__listing__name {
	margin: 0;
	text-transform: none;
	font-size: 2rem;
	color: $gray;
	a,
	a:hover,
	a:focus, {
		color: inherit;
	}
}

/*======================================================
** 			|		 fancy new print styles			 |				**
======================================================== */

@media print {
	body.styles {
		background: none;
	}
	#pathLogo {
		margin: 0 auto;
		float: none;
		height: auto;
		text-align: center;
	}
	#pathLogo img {
		max-height: 90px;
	}
	#otherLogos, #page-heading, #mainNav, #featuredHistory, #addThisMobile, #addThisRecommended, footer, .bikepath__downloads {
		display: none;
	}
	#main {
		margin-top: 0;
		width: auto;
	}
	#mainContent {
		padding: 0;
		max-width: 600px;
		margin: 0 auto;
	}
	/*	single wide column*/
	.grid-main {
		display: block;
		padding: 0;
		margin: 0;
	}
	.bikepath__search,
	.bikepath__side,
	.bikepath__details,
	.bikepath__list {
		float: none;
		width: auto;
		background: none;
		padding: 0;
	}
	.bikepath__subtitle {
		margin: 1em 0;
	}
	/*	listing tweaks	*/
	.listing.grid {
		display: block;
		padding-top: 0;
		margin-bottom: 1em;
	}
	.listing .listingPhoto {
		width: 40%;
	}
	.listing .listingDescCol, .listing.grid .listingAddress {
		width: 60%;
		float: left;
	}
	.listing .listingDescCol p {
		display: none;
	}
	.listing .listingAddress {
		min-height: 0;
	}
	.listing .listingAddress .line .glyphicon, .listing .listingAddress .line .bullicon {
		display: none;
	}
	.bikepath__directions {
		max-height: none;
		overflow-y: visible;
	}
	.directions__direction {
		padding-top: .5em;
		padding-bottom: .5em;
	}
	.glyphicon glyphicon-hand-right {
		color: #fdb916 !important;
	}
	.directions__direction__distance {
		color: #108f36 !important;
	}
		.destination__list .destination__list__item {
			padding: 1rem 0 !important;
			margin: 0;
		}
		.bikepath__returnlink {
			// No need to see this on print.
			display: none !important;
		}
		
		
	/*======================================================
	**   print layout for bike paths page = "cue sheets"  **
	======================================================== */
	.bikepath__side {
		width: 100% !important;
		padding: 0 15px !important;
		
		.bikepath__directions {
			max-height: none !important;
			height: auto !important;
			width: 100% !important;
			overflow: hidden !important;
			
			.bikepath__direction {
				page-break-inside: avoid;
				
				font-size: 16px;
			}
		}
	}
	.bikepath__details {
		width: 100% !important;
		display: none !important;
	}
}

.first,
.directionsButton {
	display: none;
}

/*======================================================
** 			|		 Media Queires			|				**
======================================================== */
@media (max-width: 1205px) {
	.bikepath__search {
		width: 208px;
	}
}
@media (max-width: $screen-sm-min) {
	.bikepath > .row {
		// not flex anymore!
		display: block;
	}
	.bikepath__details {
		display: none;
	}
	.bikepath__search {
		width: 100%;
	}
	.bikepath__list {
		width: 100%;
	}
	#container .listing-route-name {
		padding: 0.3em 0 .1em;
		padding-right: .5em;
		padding-left: 2em;
		position: relative;
	}
}
@media (max-width: 700px) {
	body
	.panel--intro {
		text-align: center;
		margin-bottom: 0;
	}
	.panel--intro > h1 {
		margin: auto;
	}
}
@media (max-width: 480px) {
	.listing .listingAddress .line {
		padding: 2px 0 2px 38px;
	}
	#container .intro__name {
		font-size: 30px;
	}
}
