$screen-xl-min: 1500px;

// under $screen-xs-max, we need to make sure the image boxes aren't TOO too big.
// For example, on tablet - these things will fill the entire width of the 700px-wide-tablet, and just look ugly.
// instead, shrink and center.
$width-imgbox-xs-max: 450px;
$height-imgbox-xs-max: floor($width-imgbox-xs-max * .7);

.main {

	.main__tricol {

		// tricol, with intro text (heading) and outtro text (paragraph.
		// only used on the homepage.
/*

		background: linear-gradient(rgba(255,255,255,0) 10%, rgba(255,255,255,0.5) 20%, #fff 48%),
					url("/images/bac_main_home.jpg") no-repeat center center/cover;
		border-bottom: .3rem solid $brand-secondary;
		color: $brand-primary;

		// make sure we leave room for the fixed header!
		padding-top: $header-height;

*/
		padding-bottom: 3rem;

		.main__tricol__row {
			// vertical separation between rows.
			margin-bottom: 3rem;

			&:first-child {
				margin-bottom: 0;
			}

			.main__tricol__intro {
				padding-left: 0;
				background-color: $brand-primary;
				color: $brand-secondary;
				h2 {
					color: inherit;
				}
			}
		}

		.main__tricol__intro,
		.main__tricol__outtro {
			text-align: center;
		}
		.main__tricol__col {
			overflow: hidden;
		}
		.main__tricol__outtro {
			p {
				font-size: $font-size-large;
			}
		}

		.imgbox {
			// on homepage, three text boxes with white border and image backgrounds
			border: 2px solid $white;
			margin-bottom: 2rem;


			text-align: center;
			color: $white;

			&:hover {
				img:not(.ignore) {
					filter: brightness(.4);
				}
			}

			a {
				&:hover {
					text-decoration: none;
				}
			}
/*

			// on mobile, display in a nice line.
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
*/
			.editmode & .cke_editable {
				// Fix the image background when the ck editor is loaded.
				position: static !important;
			}

			> p {
				margin: 0;
			}

			.imgbox__text {
				padding: 2rem;
				text-align: left;
				color: $white;
			}

			img:not(.ignore) {
				width: 100% !important;
				height: auto !important;
				transition: .2s filter ease-in-out;
				
				position: absolute;
				top: 0;
				left: 0;
				z-index: -1;

				// We assume all mobile browsers and 90% of desktop browsers support object-fit!
				object-fit: cover;
				object-position: center;
				width: 100% !important;
				height: 100% !important;
/*

				.editmode & {
					// Display image normally when in editmode!
					position: static;
				}
*/
			}

			// desktop styles for these image boxes.
			display: block;
			margin-bottom: 0;

			// try to be more or less square.
			width: 100%;
			height: 70vw;
			@media (max-width: $screen-xs-max) {
				max-width: $width-imgbox-xs-max;
				max-height: $height-imgbox-xs-max;
				margin: 0 auto 2rem;
			}
			@media (min-width: $screen-sm-min) {
				height: 25rem;
				height: 26vw;
			}

			position: relative;
			z-index: 1;

			.imgbox__text {
				// center contents on smaller screens!
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-content: center;
				text-align: center;
				height: 100%;

				transform: translateY(.001rem);
				transition: .1s transform ease-in;

				@media (min-width: $screen-md-min) {
					// disable flexbox
					display: block;
					height: auto;
					margin: 15% 10%;
				}
				@media (min-width: $screen-xl-min) {
					margin: 25% 20%;
				}

				h1,h2,h3,
				.h1,.h2,.h3,
				a {
					margin: 0;
					color: inherit;
				}
				a:hover,
				a:focus {
					text-decoration: none;
				}
				h1,.h1 {
					font-size: 2.5rem;
				}
				h2,.h2 {
					font-size: floor($font-size-h2 * .6);
					@media (min-width: $screen-xl-min) {
						// just slightly smaller than normal H2.
						font-size: floor($font-size-h2 * .93);
					}

					margin-bottom: 1em;

					@media (min-width: $screen-md-min) {
						// Weird bottom border underneath the H2.
						display: inline-block;
						position: relative;
						padding-bottom: 1rem;
						&::after {
							border-bottom: 1px solid currentcolor;
							content: "";
							display: block;
							position: absolute;
							left: 10%;
							bottom: 0;
							width: 80%;
						}
					}
				}
				h3,.h3 {
					font-size: 1.9rem;
					display: none;

					@media (min-width: $screen-md-min) {
						display: block;
					}
				}
				p {
					display: none;

					@media (min-width: $screen-md-min) {
						display: block;
					}

					font-size: floor($font-size-base * 1.2);
					@media (min-width: $screen-xl-min) {
							margin-bottom: 4rem;
					}
				}

				// all paragraphs and headings.
	 			text-shadow: 0 1px 1px rgba(0,0,0,0.6);

			}
 			&:hover {
				// some dark overlay for the image on hover
			}
		}
	}

	.main__onecol {
		// single column of content, usually white background.
		// blue background on homepage.

		padding-top: 3rem;
		padding-bottom: 3rem;

		.homepage & {
			background-color: $brand-primary;
			color: $white;

			h1,h2,h3,h4,h5,h6,
			.h1,.h2,.h3,.h4,.h5,.h6 {
				color: $brand-secondary;
			}
		}


		img {
			// regular images should shrink in mobile
			@media (max-width: $screen-xs-max) {
				max-width: 100%;
				height: auto !important;
			}
		}
	}
	
	
	/*======================================================
	** 			|     PRINT VIEW       |  		  **
	======================================================== */
	@media print {
		.main__onecol {
			padding-top: 1rem;
		}
	}
}
