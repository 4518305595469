/*
	EVENT LIST PAGE
*/
.cmsEvents.previewMode {
	.event {
		border: .5em solid $gray-lighter;
		padding: 2rem;
		margin-bottom: 2rem;
		
		h4 {
			a {
				// dumb <a> tag color
				color: inherit;
			}
		}
		
		.eventstartend {
			display: none;
			text-align: center;
			font-size: 2.5rem;
			color: $brand-secondary;
		}
		
		@media (min-width: $screen-md-min), (max-width: $screen-xs-max) {
			.eventdetail {
				margin-bottom: 5rem;
				
				// make the date show next to the text
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
			
				.eventimg {
					flex-basis: 30%;
					flex-grow: 1;
				}
				.eventtime {
					display: none;
				}
				.eventtext {
					margin-left: 3%;
					flex-basis: 67%;
					flex-grow: 2;
				}
			}
		}
	}
		
	@media (min-width: $screen-sm-min) {
		// on mobile, go 50/50 width
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;
		
		.event {
			width: 49%;
			margin-bottom: 4rem;
		}
	}
}

/*
	EVENT ARTICLE PAGE
*/


/*======================================================
** 			|     custom events system       |  		  **
======================================================== */
.customevents {
	
	.customevents__nav {
		margin-bottom: 2rem;
	}
	
	/*======================================================
	** 			|     list of events       |  		  **
	======================================================== */
	.customevents__list {
		.customevents__list__event {
			&:not(:last-of-type) {
				// border and padding
				border-bottom: .2em solid $gray-lighter;
				padding-bottom: 8rem;
				margin-bottom: 8rem;
			}
			&.customevents__list__event--hasimg {
				// how to lay out an event with an image
				
				// if phone/small tablet
				.customevents__list__event__img {
					margin: 0 auto 1rem;
					width: 30rem;
					max-width: 100%;
					img {
						width: 100%;
						height: auto;
					}
				}
				
				// if desktop
				@media (min-width: $screen-sm-min) {
					display: flex;
					flex-direction: row;
					
					.customevents__list__event__img {
						width: 400px;
						margin: 0;
						
						img {
							width: 100%;
							@supports (object-fit: contain) {
								height: 100%;
								object-fit: contain;
							}
						}
					}
					
					.customevents__list__event__text {
						padding-left: 2rem;
					}
				}
			}
			
			
			.customevents__list__event__text {
				padding-left: 2rem;
				.customevents__list__event__text__title {
					text-transform: none;
					color: $gray;
				}
				.customevents__list__event__text__location {
					color: $gray-light;
					margin-bottom: 1em;
				}
			}
		}
	}
	
	
	/*======================================================
	** 			|     single "custom" event       |  		  **
	======================================================== */
	.customevents__single {
		&.customevents__single--hasimg {
			.customevents__single__header {
				
				// phone/small tablet
				.customevents__single__img {
					width: 40rem;
					max-width: 100%;
					margin: 0 auto;
					img {
						width: 100%;
						height: auto;
					}
				}
				
				// desktop
				@media (min-width: $screen-sm-min) {
					.customevents__single__img {
						margin: 0 1rem 0 0;
					}
				}
			}
		}
		// if no image
		.customevents__single__header {
			margin-bottom: 2rem;
		}
		
		// other stuff
		.customevents__single__meta {
			border-bottom: .2em solid $gray-lighter;
			padding-bottom: 1rem;
		}
		.customevents__single__backnav {
			border-top: .2em solid $gray-lighter;
			margin-top: 2rem;
			padding-top: 1rem;
		}
		
		.customevents__single__gallery {
			width: 500px;
			margin: 0 auto;
			max-width: 100%;
		}
	}
}


/*======================================================
** 	   three-columned vertical events on homepage	  **
======================================================== */
.featuredEvents {
	padding: 9rem 0;
}
.verticalevents {
	margin-top: 3rem;
	.verticalevents__list {
		@media (min-width: $screen-sm-min) {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
		
		.verticalevents__list__event {
			@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
				.verticalevents__list__event__date {
					font-size: 2.5rem;
				}
				.verticalevents__list__event__text__title {
					font-size: 1.8rem;
				}
			}
			@media (min-width: $screen-sm-min) {
				flex-basis: 33%;
				padding: 1rem calc(2vw + 5px);
				
				&:not(:first-of-type) {
					// vertical border only on desktop
					border-left: 1px solid $gray-lighter;
				}
			}
			
			@media (max-width: $screen-xs-max) {
				// on mobile, show border and space stuff out.
				padding: 2rem;
				margin-bottom: 2rem;
				
				&:not(:last-of-type) {
					border-bottom: 2px solid $gray-lighter;
				}
			}
		}
		
	}
	
}


/*======================================================
** 			|     printed events / events page       |  		  **
======================================================== */
@media print {
	.customevents {
		.customevents__list {
			.customevents__list__event {
				&:not(:last-of-type) {
					padding: 2rem;
					margin-bottom: 2rem;
					border-bottom: 2px solid $gray-lighter;
				}
			}
		}
	}
}