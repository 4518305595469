.previous-page {
	background-color: $gray-lighter;
	
	/*======================================================
	** 			|     PRINT VIEW       |  		  **
	======================================================== */
	@media print {
		// duh
		display: none !important;
	}
}
