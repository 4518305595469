// OVERRIDE the default Bootstrap tabs.
// -------------------------

// Give the tabs something to sit on
.nav-tabs {
  border-bottom: 0;
  > li {
    float: left;
    margin: 0;
	// at tablet and under size, we make the fact nav fullwidth.
	@media (max-width: $screen-sm-max) {
		float: none;
	}

    // Actual tabs (as links)
    > a {
	    > .fa {
		    opacity: .5;
		    font-size: 3rem;
	    }
	  color: $gray-light;
		border: 1px solid $gray-lighter;
		border-bottom: 0;
		border-radius: 0;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
		
		margin-right: 2px;
		line-height: $line-height-base;
		
		@media (max-width: $screen-sm-max) {
			// move to right side.
			.caret {
				float: right;
			}
		}
      
      &:hover {
        border-color: $nav-tabs-border-color;
        background: $nav-tabs-color-bg;
      }
    }

    // Active state, and its :hover to override normal :hover
    &.active > a {
      &,
      &:hover,
      &:focus {
        color: $nav-tabs-active-link-hover-color;
        background-color: $nav-tabs-active-link-hover-bg;
        border-top: 0;
        border-left: 0;
        border-bottom: 0;
        border-bottom-color: transparent;
        cursor: default;
      }
      .fa {
	      opacity: 1;
      }
    }
  }
  // pulling this in mainly for less shorthand
  &.nav-justified {
    @extend .nav-justified;
    @extend .nav-tabs-justified;
  }
}